<template>
  <section id="hero" class="w-full center-flex flex-col  bg-off-white">
          <div class="mx-auto m-10">
            <div class="flex flex-col items-center justify-start mb-12">
              <h1>Our Team</h1>
              <p class="w-4/5 md:w-1/2">Our team of highly trained and experienced professionals is dedicated to safeguarding your assets and ensuring the highest levels of security.</p>
            </div>
            <div class="flex flex-wrap justify-center gap-16">
              <div
                  v-for="(member, id) in teamMembers"
                  :key="id"
                  class="max-w-xs w-full shadow-md overflow-hidden flex flex-col items-center justify-center"
              >
                <img :src="member.imgPath" alt="back" class="w-64 md:w-80 h-auto" />
                <div class="h-32 p-2">
                  <p>{{member.description}}</p>
                  <p class="font-extrabold">{{member.position}}</p>
                </div>
              </div>
            </div>
          </div>
   </section>
 </template>

<script>
export default {
  name: 'AboutView',
  setup(){

    const teamMembers = [{
      description: "Cledwyn Longe",
      position: "President",
      imgPath: require('@/assets/images/team/Cledwyn Longe President.png')
    },{
      description: "Gordon Jones",
      position: "Chief Financial Officer",
      imgPath: require('@/assets/images/team/Gordon Jones Chief Financial Officer.png')
    },{
      description: "Kempford Robert",
      position: "Public Relations Officer",
      imgPath: require('@/assets/images/team/Kempford Robert Public Relations Officer.png')
    },{
      description: "Sonia Craig",
      position: "Executive Secretary",
      imgPath: require('@/assets/images/team/Sonia Craig Executive Secretary.png')
    }]
    return {
      teamMembers
    }
  }
};
</script>


<style scoped>
.center-flex{
  @apply flex justify-center items-center
}

strong{
  @apply font-extrabold
}

li{
  @apply my-5 lg:my-3
}
</style>