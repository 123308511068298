import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import AboutView from '@/views/AboutView.vue';


const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'Home',
            component: HomeView,
        },
        {
            path: '/about',
            name: 'About',
            component: AboutView,
        },
        // {
        //     path: '/',
        //     name: 'ComingSoon',
        //     component: () => import('@/sections/ComingSoon.vue'),
        // },
        {
            path: '/error',
            name: 'Error',
            component: () => import('@/views/ErrorView.vue'),
        },
        {
            path: '/:catchAll(.*)',
            redirect: '/error', // Redirect to the error page
        },
    ],
    scrollBehavior() {
        // Scroll to the top of the page for each route change
        return { left: 0, top: 0 };
    },
});

export default router;
