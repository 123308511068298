<template>
  <nav id="navbar"
       class="fixed top-0 left-0 w-full z-10 bg-off-white border-gold border-b-8">

    <!-- Logo section -->
    <router-link to="/" class="flex flex-row items-center justify-center py-3">
      <img class="h-16 md:h-36 w-auto" src="../assets/images/icuLogoFull.png" alt="logo">
    </router-link>

    <!-- Hamburger menu button (mobile only) -->
    <div class="flex md:hidden justify-center w-full">
      <button @click="toggleMenu" class="text-black p-2 focus:outline-none">
        <!-- Icon for the hamburger menu (three straight lines) -->
        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </button>
    </div>

    <!-- Navigation links -->
      <div v-if="menuOpen || isDesktop" class="flex flex-col md:flex-row gap-y-8 items-center justify-center bg-black text-white w-full py-4 text-3xl md:text-2xl">
        <router-link
            v-for="(link, index) in navLinks"
            :key="index"
            :to="link.path"
            class="text-white mx-4 md:my-0"
            @click="closeMenu"
        >
          {{ link.name }}
        </router-link>
      </div>

  </nav>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
  name: 'NavBar',
  setup() {
    const navLinks = [
      { name: 'Home', path: '/' },
      { name: 'About', path: '/about' }
    ];

    const menuOpen = ref(false);
    const isDesktop = ref(false);

    const toggleMenu = () => {
      menuOpen.value = !menuOpen.value;
    };

    const closeMenu = () => {
      if (!isDesktop.value) {
        menuOpen.value = false;
      }
    };

    const handleResize = () => {
      isDesktop.value = window.innerWidth >= 768;
      if (isDesktop.value) {
        menuOpen.value = false;
      }
    };

    onMounted(() => {
      handleResize();
      window.addEventListener('resize', handleResize);
    });

    return {
      navLinks,
      menuOpen,
      toggleMenu,
      closeMenu,
      isDesktop,
    };
  }
};
</script>

<style scoped>

/* Full width nav */
#navbar {
  background-color: white;
}

/* Link styles for mobile and desktop */
.router-link-active {
  font-weight: bold;
}

/* Additional styles */
@media (max-width: 768px) {
  .router-link {
    font-size: 2rem;
    padding: 1rem 0;
    text-align: center;
  }
}

</style>
